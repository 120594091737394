import React, { Component } from 'react';
import _ from 'lodash';
import { lazyload } from 'react-lazyload';

import styles from './LogoList.module.postcss';

const DEFAULT_LOGOS = [
  'https://static.seattlefoodtruck.com/logos/AmazonLogo-300x200.png',
  'https://static.seattlefoodtruck.com/logos/Bungie-300x200.png',
  'https://static.seattlefoodtruck.com/logos/comcastlogo-300x200.png',
  'https://static.seattlefoodtruck.com/logos/Expedia-300x200.png',
  'https://static.seattlefoodtruck.com/logos/googlelogo-300x200.png',
  'https://static.seattlefoodtruck.com/logos/MicrosoftLogo-300x200.png',
  'https://static.seattlefoodtruck.com/logos/privateerholdingslogo-300x200.png',
  'https://static.seattlefoodtruck.com/logos/Starbucks-300x200.png',
];

const RESIDENTIAL_LOGOS = [
  'https://static.seattlefoodtruck.com/logos/Greystar.png',
  'https://static.seattlefoodtruck.com/logos/CBRE.png',
  'https://static.seattlefoodtruck.com/logos/AVA.png',
  'https://static.seattlefoodtruck.com/logos/AvalonBAy.png',
  'https://static.seattlefoodtruck.com/logos/Equity.png',
  'https://static.seattlefoodtruck.com/logos/Windermere.png',
];

const BREWERY_LOGOS = [
  'https://static.seattlefoodtruck.com/logos/BlackRaven.png',
  'https://static.seattlefoodtruck.com/logos/Broadview.png',
  'https://static.seattlefoodtruck.com/logos/Locus.png',
  'https://static.seattlefoodtruck.com/logos/Rooftop.png',
  'https://static.seattlefoodtruck.com/logos/UrbanFamily.png',
  'https://static.seattlefoodtruck.com/logos/Figurehead.png',
];

const COMMUNITY_LOGOS = [
  'https://static.seattlefoodtruck.com/logos/Bumbershoot.png',
  'https://static.seattlefoodtruck.com/logos/TasteWA.png',
  'https://static.seattlefoodtruck.com/logos/Pridefest.png',
  'https://static.seattlefoodtruck.com/logos/Sasquatch.png',
  'https://static.seattlefoodtruck.com/logos/QAFarmers.png',
];

const PREMIUM_PROMO_LOGOS = [
  'https://static.seattlefoodtruck.com/logos/AmazonLogo-300x200.png',
  'https://static.seattlefoodtruck.com/logos/Bungie-300x200.png',
  'https://static.seattlefoodtruck.com/logos/comcastlogo-300x200.png',
  'https://static.seattlefoodtruck.com/logos/Expedia-300x200.png',
  'https://static.seattlefoodtruck.com/logos/googlelogo-300x200.png',
];

const PORTLAND_LOGOS = [
  'https://static.seattlefoodtruck.com/logos/Adidas.png',
  'https://static.seattlefoodtruck.com/logos/Airbnb.png',
  'https://static.seattlefoodtruck.com/logos/BoeingLogo.png',
  'https://static.seattlefoodtruck.com/logos/ColumbiaSportsWear.png',
  'https://static.seattlefoodtruck.com/logos/IntelLogo.png',
  'https://static.seattlefoodtruck.com/logos/NikeLogo.png',
  'https://static.seattlefoodtruck.com/logos/USBank.png',
  'https://static.seattlefoodtruck.com/logos/YahooLogo.png',
];

@lazyload({
  height: 200,
  once: true,
  offset: 100
})
class LogoList extends Component {
  render() {
    const { title, residential, breweries, community, premiumPromo, portland } = this.props;

    let logos = DEFAULT_LOGOS;
    let organizations = 'campuses';

    if (residential) {
      logos = RESIDENTIAL_LOGOS;
      organizations = 'companies';
    } else if (breweries) {
      logos = BREWERY_LOGOS;
      organizations = 'locations';
    } else if (community) {
      logos = COMMUNITY_LOGOS;
      organizations = 'companies';
    } else if (premiumPromo) {
      logos = PREMIUM_PROMO_LOGOS;
    } else if (portland) {
      logos = PORTLAND_LOGOS;
      organizations = 'companies';
    }

    const logoList = _.map(logos, (logo, i) => {
      return (
        <div key={'logo' + i}>
          <img src={logo} alt="" />
        </div>
      );
    });



    return (
      <div>
        {!premiumPromo && !title &&
          <h4>Some of the {organizations} we work with</h4>
        }
        {!premiumPromo && title &&
          <h4>{title}</h4>
        }
        <div className={styles.LogoList}>
          {logoList}
        </div>
      </div>
    );
  }
}

export default LogoList;
