// NPM Requirements
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// Components
import Hero from './Hero';
import Meta from '../shared/Meta';
import CostEstimator from './CostEstimator';
import Triple from './Triple';
import VendorsToChooseFrom from './VendorsToChooseFrom';
import CtaImage from './CtaImage';
import LogoList from './LogoList';
import AnyEvent from './AnyEvent';

// Actions
import { clearServerRenderedPath } from '../../actions/uiActions';

// Selectors
import { getCityConfig } from '../../selectors';

// Misc
import IsoMorphUtils from '../../modules/isoMorphUtils';
import { getUrl } from '../../modules/helpers';
import styles from '../../../stylesheets/public/layouts/AboutPage.module.postcss';
import tripleStyles from './Triple.module.postcss';

class DessertCatering extends Component {
  componentDidMount() {
    if (IsoMorphUtils.serverRendered(this.props)) {
      this.props.clearServerRenderedPath();
    }
  }

  render() {
    const { location, cityConfig } = this.props;

    const vehicleType = cityConfig.vehicle_type.toLowerCase();

    return (
      <div className="CateringPage">
        <Meta
          title={`Dessert Food ${cityConfig.vehicle_type} Catering - Weddings, Parties &amp; Events - ${cityConfig.site_title}`}
          type="website"
          url={getUrl()}
          description={`Satisfy any sweet tooth and bring ${cityConfig.city_name}'s best dessert food ${vehicleType}s to any event! Our free request form is your ticket to dessert heaven and access to hundreds of local food ${vehicleType}s.`}
          structuredData={{
            breadcrumbs: { listItems: [
              {
                "@type": "ListItem",
                "position": 2,
                "name": "Dessert Catering",
                "item": cityConfig.base_url + location.pathname,
              }
            ]}
          }}
        />

        <Hero photo="https://static.seattlefoodtruck.com/photos/DESSERT-HEADER.jpeg">
          <h1>Dessert Food {cityConfig.vehicle_type} Catering</h1>
          <p>Satisfy any sweet tooth and bring {cityConfig.city_name}'s best dessert food {vehicleType}s to any event! Our free request form is your ticket to dessert heaven and access to hundreds of local food {vehicleType}s.</p>
          <a href="/catering/register" className="Button">
            Get started
          </a>
        </Hero>

        <section className="PaddedSection">
          <div className="Container">
            <h3>How it works</h3>
            <div className={tripleStyles.Triple_wrapper}>
              <Triple
                image="https://static.seattlefoodtruck.com/photos/catering1.png"
                title="1. Answer a few questions"
                text={`Use our free online request form to share details about your upcoming catering event and gain access to over 500 of ${cityConfig.city_name}'s best food ${vehicleType}s that fit your criteria.`}
              />
              <Triple
                image="https://static.seattlefoodtruck.com/photos/catering2.png"
                title="2. Compare quotes"
                text="We’ll send you quotes from a selection of best-suited vendors. You can chat with them directly to find out more about their food or drink offerings and coordinate the logistics of your event."
              />
              <Triple
                image="https://static.seattlefoodtruck.com/photos/catering3.png"
                title={`3. Book your ${vehicleType}`}
                text={`Choose your favorite food ${vehicleType}s for your event and book directly with the vendor. We never add commission or service fees, so you'll always know you get the best deal.`}
              />
            </div>
          </div>
        </section>

        <section className="PaddedSection--morePadding">
          <div className="Container">
            <div className={styles.Cta}>
              <CtaImage image="https://static.seattlefoodtruck.com/photos/DESSERT-1.jpeg" imgClass="img-dessert1"/>
              <div className={styles.Cta_copy}>
                <h4>Have your cake and eat it too</h4>
                <p>
                  No party is complete without the final course, dessert! Whether it's a late-night donut {vehicleType} for the after-party, or an ice-cream social for your coworkers, our dessert food {vehicleType}s come in all sizes and flavors! With their bright colors and cute decor, we have an amazing selection of the best dessert food {vehicleType}s in town. Interested in some boba tea? Then bring <Link to="/food-trucks/dreamy-drinks">Dreamy Drinks</Link> food {vehicleType} to your wedding for a sweet treat guests will love all from their pink food {vehicleType}! <Link to="/food-trucks/sugar-spoon">Sugar &amp; Spoon</Link> food {vehicleType} brings the edible cookie dough craze to any party, offering indoor service or off their adorable food {vehicleType}. Our dessert vendors pair perfectly with our savory food {vehicleType}s too!
                </p>
                <div>
                <a href="/catering/register" className="Button">
                  Free Quote
                </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="PaddedSection--morePadding">
          <div className="Container">
            <div className={styles.Cta + " " + styles.Cta__reversed}>
              <CtaImage image="https://static.seattlefoodtruck.com/photos/Dessert2.jpeg" imgClass="img-dessert2"/>
              <div className={styles.Cta_copy}>
                <h4>What's the scoop?</h4>
                <p>
                  Ice cream never goes out of style! Whether it's gelato, frozen custard, or just plain ice cream, these {cityConfig.city_name} food {vehicleType}s are the perfect guest to a summer wedding! Try <Link to="/food-trucks/street-treats">Street Treats</Link> food {vehicleType} and enjoy their delicious homemade ice cream between two freshly baked cookies. Take it up a notch with a decadent sundae from {cityConfig.city_name}'s very own <Link to="/food-trucks/shug-s-soda-fountain-ice-cream">Shug's Soda Fountain and Ice Cream</Link> food {vehicleType}. Nothing goes better with hot cocoa than warm melt in your mouth donuts, and <Link to="/food-trucks/mini-the-dough-nut">Mini...The Dough Nut</Link> is a fan favorite who can keep guests warm and fed quickly at your next event.
                </p>
                <div>
                  <a href="/catering/register" className="Button">
                    Book a Food {cityConfig.vehicle_type}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <VendorsToChooseFrom options={{ food_categories: 'dessert' }} />

        <CostEstimator title="Dessert Catering Cost Estimator"/>

        <section className="PaddedSection">
          <div className="Container">
            <LogoList title="Some of the companies we serve" />
          </div>
        </section>

        <AnyEvent/>

        <section className="PaddedSection">
          <div className="Container">
            <div className={styles.BookingCta}>
              <h3>Think of us for your next event</h3>
              <p>Free no-obligation estimates from {cityConfig.city_name}'s best food {vehicleType}s.</p>
              <a
                className="Button"
                href="/catering/register"
              >
                Food {cityConfig.vehicle_type} Catering
              </a>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

DessertCatering.propTypes = {
  ui: PropTypes.object,
  clearServerRenderedPath: PropTypes.func
};

function mapStateToProps(state, props) {
  return {
    ui: state.ui,
    cityConfig: getCityConfig(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    clearServerRenderedPath() {
      dispatch(clearServerRenderedPath());
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DessertCatering);
