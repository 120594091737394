import React, { Component } from 'react';
import PropTypes from 'prop-types';

import styles from '../../../stylesheets/public/layouts/AboutPage.module.postcss';

class CtaImage extends Component {
  render() {
    const { image } = this.props;

    return (
      <div className={styles.Cta_image} style={{ backgroundImage: `url("${image}")` }}/>
    );
  }
}

CtaImage.propTypes = {
  image: PropTypes.string.isRequired,
  imgClass: PropTypes.string.isRequired,
};

export default CtaImage;
